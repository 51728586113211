import { I2PEnvironment } from '@price-for-profit/micro-services';
import { Groups, Dashboard, AdminPanelSettings, Discount, Apps } from '@mui/icons-material';
import { getAppPermissions } from '../../utility';
import { useUser } from '../../hooks';

const APPLICATION_NAMES = {
    driveAccount: 'Drive Account',
    priceManagement: 'Price Management'
}

const LINK_TITLES = {
    analyticsDashboard: 'Analytics Dashboard',
    customerManagement: 'Customer Management',
    approvals: 'Approvals',
    userConfiguration: 'User Configuration',
    discountManagement: 'Discount Management',
    listPriceManagement: 'List Price Management',
}


const routeConfig: EnvironmentNavigation[] = [
    {
        application: {
            applicationName: APPLICATION_NAMES.driveAccount,
            env: {
                local: {
                    applicationId: '41448d22-957c-4d00-b1ed-065696c43f11',
                    deployedUrl: 'https://localhost:5001/apps/account',
                },
                dev: {
                    applicationId: '41448d22-957c-4d00-b1ed-065696c43f11',
                    deployedUrl: 'https://thomas-scientific.dev.insight2profit.com/apps/account',
                },
                sandbox: {
                    applicationId: '5f735834-22a8-40d6-982e-3fad4774c4af',
                    deployedUrl: 'https://thomas-scientific.sandbox.insight2profit.com/apps/account',
                },
                prod: {
                    applicationId: 'e5c8fbce-5d18-4e80-8ba7-66cf044c29f6',
                    deployedUrl: 'https://thomas-scientific.insight2profit.com/apps/account',
                },
            },
        },
        links: [
            {
                title: LINK_TITLES.analyticsDashboard,
                url: '/analytics',
                icon: <Dashboard sx={{ color: 'primary.main' }} />,
            },
        ],
    },
    {
        application: {
            applicationName: APPLICATION_NAMES.priceManagement,
            env: {
                local: {
                    applicationId: '5de16e2f-edc0-4b4c-978f-29005a9aabda',
                    deployedUrl: 'https://localhost:5003/apps/pmt',
                },
                dev: {
                    applicationId: '5de16e2f-edc0-4b4c-978f-29005a9aabda',
                    deployedUrl: 'https://thomas-scientific.dev.insight2profit.com/apps/pmt',
                },
                sandbox: {
                    applicationId: '0ec949ae-e480-40ae-bbe0-f897c75810da',
                    deployedUrl: 'https://thomas-scientific.sandbox.insight2profit.com/apps/pmt',
                },
                prod: {
                    applicationId: 'd53e597a-2c67-4147-83a9-e7416ebfc3bd',
                    deployedUrl: 'https://thomas-scientific.insight2profit.com/apps/pmt',
                },
            },
        },
        links: [
            {
                title: LINK_TITLES.customerManagement,
                url: '/customer-management',
                icon: <Groups sx={{ color: 'primary.main' }} />,
            },
            {
                title: LINK_TITLES.approvals,
                url: '/approvals/pending',
                icon: <Groups sx={{ color: 'primary.main' }} />,
            },
            {
                title: LINK_TITLES.userConfiguration,
                url: '/user-configuration',
                icon: <AdminPanelSettings sx={{ color: 'primary.main' }} />,
            },
            // {
            //     title: LINK_TITLES.discountManagement,
            //     url: '/discount-management',
            //     icon: <Discount sx={{ color: 'primary.main' }} />,
            // },
            // {
            //     title: LINK_TITLES.listPriceManagement,
            //     url: '/list-price-management',
            //     icon: <Apps sx={{ color: 'primary.main' }} />,
            // },
        ],
    },
];

interface NavigationLink {
    title: string;
    url: string;
    icon?: JSX.Element;
}

interface EnvironmentNavigation {
    application: {
        applicationName: string;
        env: Record<Exclude<I2PEnvironment, 'model'> | 'local', { applicationId: string; deployedUrl: string }>;
    };
    links: NavigationLink[];
}

interface DriveAccountNavigation {
    application: {
        applicationId: string;
        applicationName: string;
        deployedUrl: string;
    };
    links: NavigationLink[];
    local?: boolean;
}

interface BuildDriveAccountNavigationProps {
    applicationId: string;
    environment: Exclude<I2PEnvironment, 'model'>;
    useLocal?: boolean;
    showUserConfiguration: boolean
}

export function buildDriveAccountNavigation({
    applicationId,
    environment,
    useLocal,
    showUserConfiguration
}: BuildDriveAccountNavigationProps): DriveAccountNavigation[] {
    const localEnvironment = environment === 'dev' && useLocal ? 'local' : environment;
    const user = useUser();
    const appPermissions = getAppPermissions<string>(user?.insightClaims || '');

    return routeConfig.reduce<DriveAccountNavigation[]>((navigation, { links, application }) => {
        const envConfig = application.env[localEnvironment];
        const app = {
            application: {
                applicationName: application.applicationName,
                ...envConfig,
            },
            links:
                appPermissions.every(p => p !== 'thomas-scientific.user-config-manager' && p !== 'thomas-scientific.insight') ?
                    links.filter(link =>
                        link.title !== LINK_TITLES.userConfiguration) :
                    links,
            local: applicationId === envConfig.applicationId,
        };
        navigation.push(app);
        return navigation
    }, []);
}


export const showHamburger = routeConfig.length > 1;