import { SxProps } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useLocalStorage } from '../../hooks/use-local-storage';
import { useUser } from '../../hooks';

export interface ITourGuideStep {
    label: string;
    color?: string;
    elementRef?: string;
    imageUrl?: string;
}

export interface UseTourGuideProps {
    uniqueKey: string;
    steps: ITourGuideStep[];
}

export function useTourGuide({ uniqueKey, steps }: UseTourGuideProps) {
    const user = useUser();
    const localStorageKey = `tour-guide-${uniqueKey}-${user?.id || ''}`;
    const [hideTourGuide, setHideTourGuide] = useLocalStorage(localStorageKey, false);
    const [activeStep, setActiveStep] = useState(0);
    const [currentArrow, setCurrentArrow] = useState({});
    const [currentHighlightStyle, setCurrentHighlightStyle] = useState({});
    const [activePositionStyle, setActivePositionStyle] = useState<SxProps>({});
    const maxSteps = steps.length;

    const toggleHideTourGuide = useCallback(() => {
        setHideTourGuide(prev => !prev);
    }, [setHideTourGuide]);

    useEffect(() => {
        if (steps[activeStep]) {
            const elementRefName = steps[activeStep].elementRef;
            if (elementRefName) {
                const elementRef = document.querySelector<HTMLElement>(elementRefName);
                if (elementRef) {
                    const { left, width, top, bottom, right, height } = getOffset(elementRef);
                    let leftElement = left;
                    let bottomElement = bottom;
                    if (right + 600 > window.innerWidth) {
                        setCurrentArrow(rightArrowStyle(leftElement, bottom + 7));
                        if (bottom + 340 > window.innerHeight) {
                            setCurrentArrow(bottomRightArrowStyle(leftElement, top - 55 + 7));
                            bottomElement -= 414;
                            bottomElement -= height;
                        }
                        leftElement -= 550;
                        leftElement -= width;
                    } else {
                        setCurrentArrow(leftArrowStyle(leftElement, bottom + 7));

                        if (bottom + 340 > window.innerHeight) {
                            setCurrentArrow(bottomLeftArrowStyle(leftElement, top - 55 + 7));
                            bottomElement -= 414;
                            bottomElement -= height;
                        }
                    }

                    setActivePositionStyle({
                        top: bottomElement + 5,
                        left: leftElement,
                    });
                    setCurrentHighlightStyle(highlightStyle(left - 10, width + 20, top - 10, height + 20));
                } else {
                    setActivePositionStyle({
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                    });
                    setCurrentArrow({});
                    setCurrentHighlightStyle(highlightStyle(0, 0, 0, 0));
                }
            } else {
                setActivePositionStyle({
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                });
                setCurrentArrow({});
                setCurrentHighlightStyle(highlightStyle(0, 0, 0, 0));
            }
        }
    }, [steps, activeStep]);
    const handleClose = useCallback(() => {
        setActiveStep(0);
        toggleHideTourGuide();
    }, [toggleHideTourGuide]);

    const handleNext = useCallback(() => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }, []);

    const handleBack = useCallback(() => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }, []);

    return {
        hideTourGuide,
        toggleHideTourGuide,
        handleClose,
        handleNext,
        handleBack,
        maxSteps,
        currentArrow,
        currentHighlightStyle,
        activePositionStyle,
        activeStep,
    } as const;
}

function getOffset(el: HTMLElement) {
    const rect = el.getBoundingClientRect();
    return {
        left: rect.left,
        right: rect.right,
        width: rect.width,
        top: rect.top,
        bottom: rect.bottom,
        height: rect.height,
    };
}

const ARROW_STYLE = {
    position: 'fixed',
    zIndex: 1001,
    border: '20px solid transparent',
    transition: 'all 0.5s ease',
};

const rightArrowStyle = (leftElement: number, top: number) => {
    return {
        ...ARROW_STYLE,
        borderLeftColor: '#FAFAFA',
        borderRight: '0',
        top: top + 'px',
        left: leftElement + 10 + 'px',
        transform: 'rotateZ(270deg)',
    };
};

const bottomLeftArrowStyle = (leftElement: number, top: number) => {
    return {
        ...ARROW_STYLE,
        borderLeftColor: '#FAFAFA',
        borderRight: '0',
        top: top + 'px',
        left: leftElement + 44 + 'px',
        transform: 'rotateZ(90deg)',
    };
};

const bottomRightArrowStyle = (leftElement: number, top: number) => {
    return {
        ...ARROW_STYLE,
        borderLeftColor: '#FAFAFA',
        borderRight: '0',
        top: top + 'px',
        left: leftElement - 2 + 'px',
        transform: 'rotateZ(90deg)',
    };
};

const leftArrowStyle = (leftElement: number, top: number) => {
    return {
        ...ARROW_STYLE,
        borderRightColor: '#FAFAFA',
        borderLeft: '0',
        top: top + 'px',
        left: leftElement + 44 + 'px',
        transform: 'rotateZ(90deg)',
    };
};

const highlightStyle = (left: number, width: number, top: number, height: number) => {
    return {
        pointerEvents: 'auto',
        transition: 'all 0.5s ease',
        boxShadow: '0 0 0 99999px rgba(0, 0, 0, .8)',
        borderRadius: '.1em',
        border: '1px solid rgba(250, 250, 250, .4)',
        position: 'fixed',
        zIndex: 999,
        background: 'transparent',
        top: top + 'px',
        left: left + 'px',
        width: width + 'px',
        height: height + 'px',
    };
};
