import { useMutation } from '@tanstack/react-query';
import { useService } from '../providers';
import { EventData, UserActivityEventPayload } from '@price-for-profit/micro-services';
import { CLIENT_ID } from '../constants';
import { useUser } from '../hooks';

interface UserActivityData {
    userDisplayName?: string;
    userEmail?: string;
    scopes?: string[];
    additionalData?: Record<string, string>;
}
interface UserActivityEvent extends UserActivityData {
    userId?: string;
    eventType: 'PageView' | 'ButtonClick';
    correlationId?: string;
    action: string;
}

export function useUserActivityEvent() {
    const { eventService, appConfig } = useService();
    const user = useUser();

    return useMutation<EventData, Error, UserActivityEvent>({
        mutationKey: ['user-activity-login'],
        mutationFn: ({
            action,
            eventType,
            scopes,
            userId,
            userDisplayName,
            userEmail,
            correlationId,
            additionalData,
        }) => {
            const payload: UserActivityEventPayload<UserActivityData> = {
                action,
                applicationId: appConfig.appId,
                clientId: CLIENT_ID,
                data: {
                    userDisplayName: userDisplayName ?? 'no user name',
                    userEmail: userEmail ?? 'no user email',
                    scopes,
                    ...additionalData,
                },
                eventType,
                userId: userId ?? user?.id ?? 'no user id',
                timeStamp: new Date(),
                correlationId,
            };
            return eventService.logUserActivity(payload);
        },
        onError: () => {
            console.error('there was a problem capturing user event');
        },
    });
}
