import { ExpandMore } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    List,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import { useInsightEnvironment } from '../../hooks/use-insight-environment';
import { buildDriveAccountNavigation } from './navigation-config';
import { NavigationLink } from './navigation-link';
import React from 'react';
import { Copyright } from './copyright';

interface LeftNavigationProps {
    applicationId: string;
    serviceBaseUrl: string;
    useLocal: boolean;
    showUserConfiguration: boolean;
    onClose(): void;
    footerLogo: JSX.Element;
}
export function LeftNavigation({ applicationId, serviceBaseUrl, useLocal, onClose, footerLogo, showUserConfiguration }: LeftNavigationProps) {
    const environment = useInsightEnvironment({ serviceBaseUrl });

    const leftNavConfig = buildDriveAccountNavigation({
        applicationId,
        environment,
        useLocal,
        showUserConfiguration,
    });
    const [state, setState] = React.useState(() => {
        return leftNavConfig.reduce<Record<string, boolean>>((state, config) => {
            state[config.application.applicationId] = Boolean(config.local);
            return state;
        }, {});
    });

    const handleExpandChange = (id: string) => {
        return () => {
            setState(prev => ({ ...prev, [id]: !prev[id] }));
        };
    };
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box component='nav' sx={{ overflow: 'auto', flex: '1 1 75%' }}>
                {leftNavConfig.map(({ local, application, links }) => (
                    <Accordion
                        key={application.applicationId}
                        expanded={state[application.applicationId]}
                        onChange={handleExpandChange(application.applicationId)}
                    >
                        <AccordionSummary
                            sx={{ backgroundColor: '#F3F3F3' }}
                            expandIcon={<ExpandMore sx={{ color: 'secondary.main' }} />}
                        >
                            <Typography>{application.applicationName}</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0 }}>
                            <List disablePadding>
                                {links.map(({ title, url, icon }) => (
                                    <NavigationLink
                                        onClose={onClose}
                                        key={`${application.applicationId}${url}`}
                                        local={Boolean(local)}
                                        deployedUrl={application.deployedUrl}
                                        url={url}
                                    >
                                        {Boolean(icon) && <ListItemIcon>{icon}</ListItemIcon>}
                                        <ListItemText inset={!Boolean(icon)} primary={title} />
                                    </NavigationLink>
                                ))}
                            </List>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
            <Box
                sx={{
                    flex: '0 0 auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    justifySelf: 'end',
                    p: 1,
                }}
            >
                <Box textAlign='center'>{footerLogo}</Box>
                <Copyright />
            </Box>
        </Box>
    );
}
