import {
    AuthenticationResult,
    EventMessage,
    EventType,
    InteractionRequiredAuthError,
    IPublicClientApplication,
} from '@azure/msal-browser';
import { MsalProvider, useAccount, useMsal } from '@azure/msal-react';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHandleAccount } from '../hooks';
import { CustomNavigationClient } from '../startup';
import { useAuthState } from '../stores';
import { DefaultUserInfo } from '../types';

type AuthProviderProps = {
    clientApp: IPublicClientApplication;
    getUserInfo?: (user: DefaultUserInfo) => app.UserInfo;
};

export function ProvideAuth({ children, getUserInfo, clientApp }: React.PropsWithChildren<AuthProviderProps>) {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { handleUserAccount, setUserNull } = useHandleAccount({
        getUserInfo,
    });

    React.useEffect(() => {
        const navigationClient = new CustomNavigationClient(navigate);
        clientApp.setNavigationClient(navigationClient);

        const callbackId = clientApp.addEventCallback((event: EventMessage) => {
            switch (event.eventType) {
                case EventType.ACQUIRE_TOKEN_SUCCESS:
                    const tokenPayload = event.payload as AuthenticationResult;
                    // Verify Insight claims here.
                    // console.log('TOKEN SUCCESS', event, tokenPayload, tokenPayload?.account);
                    const tokenAccount = tokenPayload.account;
                    if (!tokenPayload.fromCache && tokenAccount) {
                        clientApp.setActiveAccount(tokenAccount);
                        handleUserAccount(tokenAccount);
                    }
                    break;
                case EventType.LOGIN_SUCCESS:
                    const loginPayload = event.payload as AuthenticationResult;
                    // Verify Insight claims here.
                    console.log('LOGIN SUCCESS', event, loginPayload, loginPayload?.account);
                    const loginAccount = loginPayload.account;
                    if (loginAccount) {
                        clientApp.setActiveAccount(loginAccount);
                        handleUserAccount(loginAccount);
                    }
                    break;
                case EventType.LOGOUT_END:
                    setUserNull();
                    break;
                case EventType.ACQUIRE_TOKEN_FAILURE:
                    const error = event.error;
                    if (error instanceof InteractionRequiredAuthError) {
                        // console.log('interaction required');
                        // console.log(error);
                    }
                    break;
                default:
                    break;
            }
        });

        return () => {
            if (callbackId) {
                clientApp.removeEventCallback(callbackId);
            }
        };
    }, [clientApp, enqueueSnackbar, handleUserAccount, setUserNull]);

    return (
        <MsalProvider instance={clientApp}>
            <SelectAccount getUserInfo={getUserInfo}>{children}</SelectAccount>
        </MsalProvider>
    );
}
interface SelectAccountProps {
    getUserInfo?: (user: DefaultUserInfo) => app.UserInfo;
}
function SelectAccount({ children, getUserInfo }: PropsWithChildren<SelectAccountProps>) {
    const authState = useAuthState();
    const user = authState.user.get();
    const activeAccount = useAccount();
    const { accounts, instance } = useMsal();
    const { handleUserAccount } = useHandleAccount({
        getUserInfo,
    });

    useEffect(() => {
        if (!user) {
            if (activeAccount) {
                handleUserAccount(activeAccount);
            } else {
                if (accounts.length) {
                    instance.setActiveAccount(accounts[0]);
                    handleUserAccount(accounts[0]);
                }
            }
        }
    }, [activeAccount, handleUserAccount, user, accounts]);

    return <>{children}</>;
}

export const AuthInProgress = () => (
    <Typography sx={{ textAlign: 'center' }} variant='h4'>
        Authorization in progress...
    </Typography>
);
