import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useUserActivityEvent } from '../mutations';
import { InsightB2CClaims } from '..';
interface UseTrackLoginProps {
  enableTokenTracking?: boolean;
}

export function useTrackLogin({ enableTokenTracking }: UseTrackLoginProps = { enableTokenTracking: false }) {
  const { instance } = useMsal();
  const userTracking = useUserActivityEvent();

  useEffect(() => {
    const callbackId = instance.addEventCallback((event: EventMessage) => {
      if ([EventType.LOGIN_SUCCESS, EventType.ACQUIRE_TOKEN_SUCCESS].includes(event.eventType)) {
        const loginPayload = event.payload as AuthenticationResult;
        const isLoginSuccessEvent = event.eventType === EventType.LOGIN_SUCCESS;
        const isTrackableLogin = isLoginSuccessEvent || (!loginPayload.fromCache && Boolean(enableTokenTracking));
        if (isTrackableLogin) {
          const claims = loginPayload.idTokenClaims as InsightB2CClaims;
          const actionType = isLoginSuccessEvent ? 'Login' : 'Token';
          userTracking.mutateAsync({
            userId: claims.sub,
            action: actionType,
            eventType: 'ButtonClick',
            userDisplayName: claims.name,
            userEmail: claims.email,
            scopes: loginPayload.scopes,
          });
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, enableTokenTracking, userTracking]);
  return null;
}
