import { ThemeProvider, unstable_createMuiStrictModeTheme } from '@mui/material';
import React from 'react';

export function ProvideTheme({ children }: React.PropsWithChildren<{}>) {
    const theme = unstable_createMuiStrictModeTheme({
        palette: {
            primary: {
                main: '#cf113e',
                light: '#ff5568',
                dark: '#960018',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#0f7087',
                light: '#4f9fb7',
                dark: '#00455a',
                contrastText: '#ffffff',
            },
            background: {
                default: '#E1E2E1',
                paper: '#F5F5F6',
            },
        },
    });
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
