import { Box, Theme } from '@mui/material';
import React from 'react';

interface AppLogoProps {
    src: string;
    alt: string;
    maxHeight?:string
}
export function AppLogo({ src, alt, maxHeight }: AppLogoProps) {
    return (
        <Box component='img' src={src} sx={{ maxHeight:  (theme: Theme) =>  maxHeight || theme.mixins.toolbar.minHeight }} alt={alt} />
    );
}
